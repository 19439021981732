import React, { useState, useEffect } from "react";
import axios from 'axios';
import {useLocation, Link } from "react-router-dom";
import DatePickPage from './DatePickPage';
import FilterPage from './FilterPage';
import "./HotelList.css";
import { Button } from "@mui/material";
import * as PinoUtil from "../Common/PinoUtil";
import * as PinoText from "../Common/PinoTextRes";


function HotelList(props) {    
    const location = useLocation();
    //console.log(location);
    const city_id = location.pathname.split('/')[1]
    //console.log(city_id);

    const [hotel_jsons, set_hotel_jsons] = useState([]);
    const [hotel_avg_price, set_hotel_avg_price] = useState({});

    const [price_map, setPriceMap] = useState({});
    const [price_link_map, setPriceLinkMap] = useState({});

    const [available_options, set_available_options] = useState([]);    
    const [filter_option, set_filter_option] = useState([]);

    const [available_regions, set_available_regions] = useState([]);    
    const [filter_region, set_filter_region] = useState([]);
    
    const [available_stars, set_available_stars] = useState([]);    
    const [filter_star, set_filter_star] = useState([]);    

    const [available_chains, set_available_chains] = useState([]);    
    const [filter_chain, set_filter_chain] = useState([]);

    const [clustering_rule, set_clustering_rule] = useState("REGION");

    const [hide_soldout, set_hide_soldout] = useState("SHOW");

    const [is_empty_result, set_is_empty_result] = useState(false);


    useEffect(()=> {
        console.log("execute once");
        InitializeCityConfig();
        ReadHotelData();
        LoadAvgPrice();
    }, []);


    function InitializeCityConfig() {
        console.log(city_id);
        var initial_clustering = PinoUtil.PINO_CITY_LIST.get(city_id).default_clustering;
        console.log(initial_clustering);
        set_clustering_rule(initial_clustering);
        set_hide_soldout("SHOW");
    }


    function ReadHotelData() {
        axios.get('../data/'+city_id+'.csv')
        .then(function(response) {
            //console.log("sss", response.data);
            var result = response.data;
    
            var hotel_jsons_temp = [];

            const rows = result.split("\r\n");
            var hid_list = [];
            var temp_available_regions = [];
            var temp_available_options = [];
            var temp_available_chains = [];
            var temp_available_stars = [];
            for(let i = 0; i < rows.length; i++){
                //console.log(rows[i]);

                var hotel_json = PinoUtil.PINO_ParseHotelData(rows[i]);
                
                //console.log(hotel_json);
                hotel_jsons_temp.push(hotel_json);

                hid_list.push(hotel_json.hotel_id);

                // region 
                if(temp_available_regions.includes( hotel_json.region) == false) {
                    temp_available_regions.push(hotel_json.region);
                }

                // stars
                if(temp_available_stars.includes( hotel_json.hotel_star) == false) {
                    temp_available_stars.push(hotel_json.hotel_star);
                }

                // options
                var options = hotel_json.options.split('|');
                for(var a=0;a<options.length;a++) {
                    if(options[a]!=='' &  !temp_available_options.includes(options[a])) {
                        temp_available_options.push(options[a]);
                    }
                }

                // chains
                if(temp_available_chains.includes( hotel_json.chain_id) == false) {
                    temp_available_chains.push(hotel_json.chain_id);
                }
            }

            console.log("available_regions " ,temp_available_regions);
            set_available_regions(temp_available_regions);
            
            console.log("available_stars " ,temp_available_stars);
            set_available_stars(temp_available_stars);

            console.log("available_options " ,temp_available_options);
            set_available_options(temp_available_options);
            
            console.log("available_chains " ,temp_available_chains);
            set_available_chains(temp_available_chains);
            
            console.log(hotel_jsons_temp);
            set_hotel_jsons(hotel_jsons_temp);
    
            SearchPrice(hid_list);
        })
        .catch(function(error) {
            console.log("ERROR: ReadHotelData");
        })
    };
    


    // Date Picker update callback -----------------------------------------------------------------
    const OnDPpickDone = () => {
        console.log("OnDPpickDone");
        SearchPrice();
    };

    function SearchPrice(hid_list = []) {
        if(hid_list.length == 0) {
            for(var i=0;i<hotel_jsons.length;i++) {
                hid_list.push(hotel_jsons[i].hotel_id);
            }
        }
        if(hid_list.length == 0) {
            console.log("SearchPrice - empty hotel_json");
            return;
        }
        //console.log("SearchPrice", hid_list);

        // 0. reset price
        var reset_price_map = {};
        var reset_price_link_map = {};
        setPriceMap(reset_price_map);
        setPriceLinkMap(reset_price_link_map);
        
        // 1. query price
        var saved_td = PinoUtil.PINO_loadTourDate();        
        //var req_url = 'https://hj26v621uh.execute-api.ap-northeast-2.amazonaws.com/prod/hotel/price?st_date='+saved_td.st_date+'&end_date='+saved_td.end_date
        var req_url = PinoUtil.PINO_API_URL + '/hotel/price?st_date='+saved_td.st_date+'&end_date='+saved_td.end_date
                        +'&num_adult='+saved_td.num_adult+'&num_children='+saved_td.num_children
                        +'&lang_country=ko-kr&currency=KRW&hotel_ids='+hid_list.join(',');
        //console.log("SearchPrice", req_url);

        axios.get(req_url)
            .then((result)=>{ 
                var resp = result.data;
                console.log("SearchPrice result", resp);
                var temp_price_map = {};
                var temp_price_link_map = {};
                for(var z=0;z<hid_list.length;z++) {
                    temp_price_map[hid_list[z]] = PinoText.PINO_TEXT_KO.NO_ROOM_LEFT;
                    temp_price_link_map[hid_list[z]] = PinoUtil.AGODA_LINK_URL+hid_list[z];
                }

                if('results' in resp) {
                    for(var k=0;k<resp.results.length;k++) {
                        //console.log(resp.results[k].hotelId, resp.results[k].dailyRate);
                        temp_price_map[resp.results[k].hotelId] = resp.results[k].dailyRate;
                        temp_price_link_map[resp.results[k].hotelId] = resp.results[k].landingURL;
                    }
                }

                //console.log(temp_price_map);
                setPriceMap(temp_price_map);
                setPriceLinkMap(temp_price_link_map);
                //updatePrice(price_map, price_link_map);
            })
            .catch(() => {});
    }


    // Filter update callback -------------------------------------------------------------------------------
    const OnUpdateClusteringRule = (new_rule) => {
        set_clustering_rule(new_rule);
    }
    const onUpdateSoldoutHide = (new_rule) => {
        set_hide_soldout(new_rule);
    }
    const OnUpdateChainFilter = (new_filter) => {
        set_filter_chain(new_filter); 
    }
    const OnUpdateOptionFilter = (new_filter) => {
        set_filter_option(new_filter); 
    }
    const OnUpdateRegionFilter = (new_filter) => {
        set_filter_region(new_filter); 
    }
    const OnUpdateStarFilter = (new_filter) => {
        set_filter_star(new_filter); 
    }
    const onHideFilterPopup  = () => {
        showHideEmptyMSG();
    }

    const showHideEmptyMSG  = () => {
        var num = document.querySelectorAll(".hotel_item").length;
        console.log("onHideFilterPopup", num);
        if(num == 0) {
            set_is_empty_result(true);
        }
        else {
            set_is_empty_result(false);
        }
    }




    // avg price csv -------------------------------------------------------------------
    const LoadAvgPrice = () => {
        console.log("LoadAvgPrice");
        var new_avg_prices = {};

        fetch('/data/price/h_price_avg.csv')
        .then(response => response.text())
        .then(data => {
            // Do something with the parsed CSV data
            const rows = data.trim().split('\n');
            const headers = rows.shift().split(',');
            //console.log(headers);
            //console.log(rows);
            for(var i=0;i<rows.length;i++) {
                var cells = rows[i].split(',');
                new_avg_prices[cells[0]] = cells[1];
            }
            //console.log("LoadAvgPrice result", new_avg_prices);
            set_hotel_avg_price(new_avg_prices);
        })
        .catch(error => {
            // Handle error
            console.error(error);
        });

    }
    
    
    return (
        <div id='Hotel_list_div'>

            <div id='filtering_ordering_div'>

                <DatePickPage cbFinish={OnDPpickDone} bShowFull={true} />

                <FilterPage 
                    clustering_rule={clustering_rule}
                    cbUpdateCluteringRule={OnUpdateClusteringRule}

                    available_chains={available_chains}   
                    filter_chain={filter_chain} 
                    cbUpdateChainFilter={OnUpdateChainFilter}  
                    
                    available_options={available_options}  
                    filter_option={filter_option}  
                    cbUpdateOptionFilter={OnUpdateOptionFilter} 
                    
                    available_regions={available_regions}  
                    filter_region={filter_region}  
                    cbUpdateRegionFilter={OnUpdateRegionFilter} 
                    
                    available_stars={available_stars}  
                    filter_star={filter_star}  
                    cbUpdateStarFilter={OnUpdateStarFilter} 
                    
                    hide_soldout = {hide_soldout}
                    //cbUpdateSoldoutHide={onClickSoldoutCheckbox} 
                    cbUpdateSoldoutHide={onUpdateSoldoutHide} 

                    cbPopupHide={onHideFilterPopup}
                />

            </div>



            {(clustering_rule=="STAR")?
                <div className="Hotel_list_table">
                    {available_stars
                        .sort((a, b) => b[0] - a[0])
                        .map(star => (
                        <HotelListRow  key={star}
                            row_title={star}
                            clustering_region = {''}
                            clustering_star = {star}
                            hotel_jsons={hotel_jsons}
                            price_map={price_map} 
                            price_link_map={price_link_map} 
                            filter_option={filter_option}  
                            filter_chain={filter_chain} 
                            filter_region={filter_region} 
                            filter_star={filter_star} 
                            filter_hide_soldout = {hide_soldout}
                            hotel_avg_price={hotel_avg_price} 
                        />
                    ))}
                </div>
                :
                <div className="Hotel_list_table">
                    {available_regions
                        .map(region => (
                        <HotelListRow  key={region}
                            row_title={region}
                            clustering_region = {region}
                            clustering_star = {''}
                            hotel_jsons={hotel_jsons}
                            price_map={price_map} 
                            price_link_map={price_link_map} 
                            filter_option={filter_option}  
                            filter_chain={filter_chain} 
                            filter_region={filter_region} 
                            filter_star={filter_star} 
                            filter_hide_soldout = {hide_soldout}
                            hotel_avg_price={hotel_avg_price} 
                        />
                    ))}
                </div>
            }



            {is_empty_result == true &&  <EmptyResult />}

            <div className='margin_div'>
            </div>
 
        </div>
    );
}





function HotelListRow(props) {
    //console.log("HotelListRow", props);
    //console.log("HotelListRow", props.hotel_jsons.length);


    const IsVisible = (h_data) => {

        // clustering by region 
        if(props.clustering_region != '') {
            if(props.clustering_region != h_data.region) {
                return false;
            }
        }


        // clustering by star
        if(props.clustering_star != '') {
            if(props.clustering_star != h_data.hotel_star) {
                return false;
            }
        }





        // chain filter         
        if(props.filter_chain.length > 0) {
            var bSelectedChain = false;
            for(var i=0;i<props.filter_chain.length;i++) {
                if( props.filter_chain[i] == h_data.chain_id ) {
                    bSelectedChain = true;
                    break;
                }
            } 
            if(bSelectedChain == false) {
                return false;
            }
        }


        // region filter         
        if(props.filter_region.length > 0) {
            var bSelectedRegion = false;
            for(var i=0;i<props.filter_region.length;i++) {
                if( props.filter_region[i] == h_data.region ) {
                    //console.log("kkkkk");
                    //console.log(props.filter_region);
                    //console.log(h_data.region);

                    bSelectedRegion = true;
                    break;
                }
            } 
            if(bSelectedRegion == false) {
                return false;
            }
        }        


        

        // star filter         
        if(props.filter_star.length > 0) {
            var bSelectedStar = false;
            for(var i=0;i<props.filter_star.length;i++) {
                if( props.filter_star[i] == h_data.hotel_star ) {
                    bSelectedStar = true;
                    break;
                }
            } 
            if(bSelectedStar == false) {
                return false;
            }
        }        



        // Hide empty price
        if(props.filter_hide_soldout == "HIDE") {
            if(h_data.hotel_id in props.price_map) {
                if( props.price_map[h_data.hotel_id] == PinoText.PINO_TEXT_KO.NO_ROOM_LEFT) {
                    return false;
                }
            }
        }

        // option filter 
        //console.log("zzz cur filters:", props.filter_option);
        //console.log("zzz hotel options:", h_data.options);
        for(var i=0;i<props.filter_option.length;i++) {
            //console.log("filter cond:", props.filter_option[i]);
            if(h_data.options.includes(props.filter_option[i]) == false) {
                return false;
            }
        }        
        return true;
    }

    const IsVisibleAll = (h_jsons) => {
        var showCount = 0;
        for(var i=0;i<h_jsons.length;i++) {
            if(IsVisible(h_jsons[i])) {
                showCount++;
            }
        }
        if(showCount==0) {
            return false;
        }
        return true;
    }

    //console.log("kkkkk", props.hotel_jsons);

    return (
        IsVisibleAll(props.hotel_jsons)?

        <div className='Hotel_list_row'>

            <div className={'HL_tier_number_div'}><span className="tier_text"> {props.row_title} </span></div>

            <div className='HL_list_div'>{
                props.hotel_jsons
                    .filter(hotel_item => IsVisible(hotel_item))
                    .map(hotel_item => (
                        <HotelItem
                            key={hotel_item.hotel_id}
                            hotel_data={hotel_item}
                            price_map={props.price_map}
                            price_link_map={props.price_link_map}
                            filter_option={props.filter_option}
                            filter_chain={props.filter_chain}
                            hotel_avg_price={props.hotel_avg_price[hotel_item.hotel_id]}
                        />
                    ))
            }</div>

        </div>
        :<div></div>
    );
}


function HotelItem(props) {    
    //console.log("HotelItem", props);

    const ComposeAvgPriceMsg = () => {
        if(props.hotel_data.hotel_id in props.price_map && typeof props.price_map[props.hotel_data.hotel_id] == 'number') {
            if(props.hotel_avg_price !=0 ) {
                var percentage = (props.price_map[props.hotel_data.hotel_id]/props.hotel_avg_price*100 -100).toFixed(0);
                //console.log(percentage);
                if(percentage> 10) {
                    return "평소 보다 "+percentage+"% 더 비쌈";
                }
                else if(percentage< -10) {
                    return "평소 보다 "+percentage+"% 저렴";
                }
                else {
                    return "평소 가격과 비슷함";
                }
            }
        }
        return "";
    }

    const ComposeAvgPriceClass = () => {
        if(props.hotel_data.hotel_id in props.price_map && typeof props.price_map[props.hotel_data.hotel_id] == 'number') {
            if(props.hotel_avg_price !=0 ) {
                var percentage = (props.price_map[props.hotel_data.hotel_id]/props.hotel_avg_price*100 -100).toFixed(0);
                //console.log(percentage);
                if(percentage> 10) {
                    return "hotel_item_price_avg expensive";
                }
                else if(percentage< -10) {
                    return "hotel_item_price_avg cheap";
                }
                else {
                    return "hotel_item_price_avg moderate";
                }
            }
        }
        return "hotel_item_price_avg";
    }


    return (
        //<div className={'hotel_item '+ (props.hotel_data.chain_id)} style={tempStyle} >
        <div className={'hotel_item '+ (props.hotel_data.chain_id)} >
            <Link to={{
                pathname:`./${props.hotel_data.hotel_id}`, 
                state: {hotels: 123}
                }} target='_blank'> 

                <img className='hotel_item_img' src={props.hotel_data.photo_1}/>
                <div className='hotel_item_title'>{props.hotel_data.hotel_name}</div>

            </Link>

            <div className='hotel_item_price'  >
                {(props.hotel_data.hotel_id in props.price_map)?
                    <span className='hotel_item_price_span' onClick={()=>{window.open(props.price_link_map[props.hotel_data.hotel_id])} } >
                        {typeof props.price_map[props.hotel_data.hotel_id] == "string" ?
                            props.price_map[props.hotel_data.hotel_id]:
                            props.price_map[props.hotel_data.hotel_id].toLocaleString() +'원'
                         }
                        
                    </span>
                    :
                    <span className='hotel_item_price_span' onClick={()=>{window.open(props.price_link_map[props.hotel_data.hotel_id])} } > {PinoText.PINO_TEXT_KO.SEARCHING_PRICE} </span>
                }
            </div>
            <div className={ComposeAvgPriceClass()}  >   {ComposeAvgPriceMsg()}      </div>
        </div>
    );
}



function EmptyResult(props) {
    {/**
    const [hotelCount, setHotelCount] = useState(1);

    useEffect(() => {
        const divElements = document.querySelectorAll(".hotel_item");
        setHotelCount(divElements.length);
      }, []);
    console.log(props.hotel_count);

    return (
        <div>
            {props.hotel_count == 0 && <div>EMPTY</div>}
        </div>
    )
    
    */}

    return (
        <div id='div_empty_result'>
            <img id='img_empty_result' src='res/empty_dog.png' />
            <div id='msg_empty_result'>조건에 맞는 숙소가 없어요..</div>
        </div>
    )

}



export default HotelList; 