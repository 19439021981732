import React, { useState, useEffect } from "react";
//import YouTube from 'react-youtube';
import ReactPlayer from 'react-player/youtube';
import './YouTubePlayerPage.css';
import Button from '@mui/material/Button';



function YouTubePlayerPage(props) {
    const hide_style = {
        display:(props.video_id==""?"none":"block")
    }

    //console.log("jkjkj", props, hide_style);


    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
     }, []);


    return (
        <div className='YouTubePlayerPage' style={hide_style}>
            <div className='YT_video_player_div'>
                {/*<YouTube videoId = {props.video_id} className='YT_video_player' iframeClassName='YT_video_player_iframe' />*/}
                <ReactPlayer url={'https://www.youtube.com/watch?v='+props.video_id} playing={true} controls={true} className="react-player" width="100%" height="100%" />
            </div>
            <div className='YT_close_btn_div'>
                <div className='YT_close_btn' onClick={()=>props.closeCB()}>닫기</div>
            </div>
        </div>
    );

    
}

export default YouTubePlayerPage;
