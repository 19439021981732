import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import "./CityList.css";
import * as PinoUtil from "../Common/PinoUtil";




function CityItem(props) {    
    return (
        <li className="city_li_div">
            <Link to={{
                pathname:`/${props.city_id}`, 
                state: {city: 123}
                }} > 

                <img className='city_item_img' src={props.city_img} alt='city_img'/>
                <div className='city_ltem_title'>{props.city_name} </div>

            </Link>
            
        </li>
    );
}





function CityList(props) {    

    useEffect(()=> {
        console.log("execute once");

    }, [])

    
    return (
        <div id="city_list">
            <div id='main_banner_div'>
                <img id="main_banner_img" src="/res/main_banner_airport.jpg" alt='main_banner' />
                <div id="main_banner_title_div" >
                    <div id="main_banner_title">어디로 떠나볼까요?</div>
                </div>
            </div>

            <div id='country_ul_div'>
                <div id='country_ul'>
                    {
                        Array.from(PinoUtil.PINO_COUNTRY_LIST).map(([cc_id,cc_obj])  => {
                            return (
                                <div key={cc_id}>
                                    <div className='country_head'>
                                        <img className='country_flag' src={'/res/cc-flags/'+cc_id+'.png'} alt={cc_id}></img>
                                        <span className='country_name'>{cc_obj.country_name}</span>
                                    </div>
                                    <ul className='city_ul'>
                                        {cc_obj.cities.map((city_id, city_obj) => (
                                            <CityItem key={city_id}  city_id={city_id}   city_name={PinoUtil.PINO_CITY_LIST.get(city_id).city_name}  city_img={"res/city/" + city_id + ".jpg"} />
                                        ))}
                                    </ul>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default CityList; 