import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import "./CityTable.css";
import * as PinoUtil from "../Common/PinoUtil";








function CityTable(props) {    

    const [city_jsons, set_city_jsons] = useState([]);    

    const CITY_INFOS = [
        { id: 140, name: "후쿠오카", img: "https://live.staticflickr.com/6004/6013308517_6c427f1da1_q.jpg" },
        { id: 133, name: "오사카", img: "https://live.staticflickr.com/65535/47942767693_cff8aeb9ee_q.jpg" },
        { id: 159, name: "삿포로", img: "https://live.staticflickr.com/4005/4340886853_9e61cef2fc_q.jpg" },
    ];

    useEffect(()=> {
        console.log("execute once");
        ReadCityData();
    }, [])


    function ReadCityData() {
        axios.get('../data/city_sheet.csv')
        .then(function(response) {
            //console.log("sss", response.data);
            var result = response.data;
    
            var city_jsons_temp = [];

            const rows = result.split("\r\n");
            for(let i = 0; i < rows.length; i++){
                //console.log(rows[i]);

                var city_json = PinoUtil.PINO_ParseCityInfoData(rows[i]);
                
                //console.log(city_json);
                city_jsons_temp.push(city_json);
            }
    
            //console.log(city_jsons_temp);
            set_city_jsons(city_jsons_temp);

        })
        .catch(function(error) {
            console.log("ERROR: ReadCityData");
        })
    };
    



    
    return (
        <div id="city_table">
            <table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Img</th>
                </tr>
                </thead>
                <tbody>
                {city_jsons.map(city => (
                    <tr key={city.id}>
                    <td>{city.id}</td>
                    <td>{city.name}</td>
                    <td><img src={city.img}/></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default CityTable; 