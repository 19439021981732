import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import './DatePickPage.css';
import './FilterPage.css';
import * as PinoUtil from '../Common/PinoUtil';
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function DatePickPage(props) {
    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [num_adult, setNumAdult] = useState(130);
    const [num_children, setNumChildren] = useState(0);

    const popup_page = useRef();    

    const onChangeDate = (dates) => {
        console.log("onChangeDate", dates);
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    function showDPPopup() {
        popup_page.current.style.display = "block";
        document.body.style.overflow = "hidden";
    }

    function hideDPPopup() {
        popup_page.current.style.display = "none";
        document.body.style.overflow = "";
    }
    

    const LoadDPdate = () => {
        var saved_td = PinoUtil.PINO_loadTourDate();
        console.log("LoadDPdate", saved_td);
        setStartDate(new Date(saved_td.st_date));
        setEndDate(new Date(saved_td.end_date));
        setNumAdult(saved_td.num_adult);
        setNumChildren(saved_td.num_children);
    }

    useEffect(()=> {
        console.log("execute once");
        LoadDPdate();
    }, [])

    const IncAdult = () => {
        if(num_adult<9) {
            setNumAdult(num_adult+1);
        }
    }
    const DecAdult = () => {
        if(num_adult>1) {
            setNumAdult(num_adult-1);
        }
    }

    const IncChildren = () => {
        if(num_children<9) {
            setNumChildren(num_children+1);
        }
    }
    const DecChildren = () => {
        if(num_children>0) {
            setNumChildren(num_children-1);
        }
    }

    const OnClickDoneBtn = () => {
        
        if(startDate < endDate) {
            var st_str = (startDate.toISOString().split('T')[0]);
            var end_str = (endDate.toISOString().split('T')[0]);            
            console.log("Save Tour Date", st_str, end_str, num_adult, num_children);
            PinoUtil.PINO_saveTourDate(st_str, end_str, num_adult, num_children);
            props.cbFinish();
            hideDPPopup();
        }
        else {
            alert("입력 에러");
        }

    }


    return (
        <div className='DP_sum_div'>
            <div className='DP_seleted_date_div' onClick={showDPPopup}>
                {props.bShowFull === true?
                    PinoUtil.PINO_DisplayDateText(startDate)+" ~ "+PinoUtil.PINO_DisplayDateText(endDate)+"  / 성인 "+num_adult+"명, 아동 "+num_children+"명"
                    : PinoUtil.PINO_DisplayDateText(startDate)+" ~ "+PinoUtil.PINO_DisplayDateText(endDate)
                }
            </div>
            

                <div className='option_page_popup_bg'  ref={popup_page}>
                    <div className='option_page_popup'>    
                        <div className='option_page_title_div' >
                            <div className='option_page_title'>날짜 / 인원 선택</div>
                            <div className='option_page_close_btn' onClick={hideDPPopup}><CloseIcon/></div>
                        </div>

                        <div className='option_page_content_div'>
                            <div className='DP_seleted_date_div'>
                                {PinoUtil.PINO_DisplayDateText(startDate)} ~ {PinoUtil.PINO_DisplayDateText(endDate)} / 성인 {num_adult}명, 아동 {num_children}명
                            </div>
                            <div className='DP_calendar_div'>
                                <DatePicker selected={startDate} onChange={onChangeDate} startDate={startDate} endDate={endDate} selectsRange inline />
                            </div>

                            <div>
                                <div className='DP_person_select_div'>
                                    <div className='DP_person_select_div_key'>성인</div>
                                    <div className='DP_person_select_div_value'>
                                        <IconButton aria-label="-"  onClick={DecAdult}>  <RemoveIcon className='number_control_button'/></IconButton>
                                        {num_adult}
                                        <IconButton aria-label="+"  onClick={IncAdult} >  <AddIcon className='number_control_button' /></IconButton>
                                    </div>
                                </div>
                                <div  className='DP_person_select_div'>
                                    <div className='DP_person_select_div_key'>아동</div>
                                    <div className='DP_person_select_div_value'>
                                        <IconButton aria-label="-"  onClick={DecChildren}>  <RemoveIcon className='number_control_button' /></IconButton>
                                        {num_children}
                                        <IconButton aria-label="+"  onClick={IncChildren} >  <AddIcon className='number_control_button' /></IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='option_page_done_btn_div'>
                            <div className='option_page_done_btn' onClick={OnClickDoneBtn} >
                                완료
                            </div>                        
                        </div>
                    </div>
                </div>

        </div>
    );
}

export default DatePickPage;
