import React, {  useRef  } from "react";
import './FilterPage.css';
import * as PinoUtil from '../Common/PinoUtil';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import TuneIcon from '@mui/icons-material/Tune';


import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


function FilterPage(props) {

    console.log(" FilterPage -- ", props);

    // Chain Filter --------------------------------------------------------------
    const cbFilterChain = (chain_id) => {
        console.log("cbFilterChain", chain_id);
        var temp_filter_chain = [...props.filter_chain];
        console.log("cbFilterChain : before", temp_filter_chain);
        var bdeleted = false;
        for(var i=0;i<temp_filter_chain.length;i++) {
            if(temp_filter_chain[i] === chain_id) {
                temp_filter_chain.splice(i,1);
                bdeleted = true;
                break;
            }
        }
        if(bdeleted === false) {
            temp_filter_chain.push(chain_id);
        }
        console.log("cbFilterChain : after", temp_filter_chain);
        props.cbUpdateChainFilter(temp_filter_chain);
    }

    
    const cbFilterChainAll = () => {
        var temp_filter_chain = [...props.filter_chain];
        console.log("cbFilterChainAll : before", temp_filter_chain);
        if(temp_filter_chain.length !== 0) {
            temp_filter_chain = [];
        }
        else {
            // do nothing
        }            
        console.log("cbFilterChainAll : after", temp_filter_chain);
        props.cbUpdateChainFilter(temp_filter_chain);
    }
    


    // Region Filter -----------------------------------
    const cbFilterRegion = (region) => {
        console.log("cbFilterRegion", region);
        var temp_filter_region = [...props.filter_region];
        console.log("cbFilterRegion : before", temp_filter_region);
        var bdeleted = false;
        for(var i=0;i<temp_filter_region.length;i++) {
            if(temp_filter_region[i] === region) {
                temp_filter_region.splice(i,1);
                bdeleted = true;
                break;
            }
        }
        if(bdeleted === false) {
            temp_filter_region.push(region);
        }
        console.log("cbFilterRegion : after", temp_filter_region);
        props.cbUpdateRegionFilter(temp_filter_region);
    }
    
    const cbFilterRegionAll = () => {
        var temp_filter_region = [...props.filter_region];
        console.log("cbFilterRegionAll : before", temp_filter_region);
        if(temp_filter_region.length !== 0) {
            temp_filter_region = [];
        }
        else {
            // do nothing
        }
            
        console.log("cbFilterRegionAll : after", temp_filter_region);
        props.cbUpdateRegionFilter(temp_filter_region);
    }
        


    

    // Star Filter -----------------------------------
    const cbFilterStar = (star) => {
        console.log("cbFilterStar", star);
        var temp_filter_star = [...props.filter_star];
        console.log("cbFilterStar : before", temp_filter_star);
        var bdeleted = false;
        for(var i=0;i<temp_filter_star.length;i++) {
            if(temp_filter_star[i] === star) {
                temp_filter_star.splice(i,1);
                bdeleted = true;
                break;
            }
        }
        if(bdeleted === false) {
            temp_filter_star.push(star);
        }
        console.log("cbFilterStar : after", temp_filter_star);
        props.cbUpdateStarFilter(temp_filter_star);
    }
    
    const cbFilterStarAll = () => {
        var temp_filter_star = [...props.filter_star];
        console.log("cbFilterStarAll : before", temp_filter_star);
        if(temp_filter_star.length !== 0) {
            temp_filter_star = [];
        }
        else {
            // do nothing
        }
            
        console.log("cbFilterStarAll : after", temp_filter_star);
        props.cbUpdateStarFilter(temp_filter_star);
    }
        


    
    // Option Filter -----------------------------------
    const cbFilterOption = (option) => {
        console.log("cbFilterOption", option);
        var temp_filter_option = [...props.filter_option];
        console.log("cbFilterOption : before", temp_filter_option);

        var bdeleted = false;
        for(var i=0;i<temp_filter_option.length;i++) {
            if(temp_filter_option[i] === option) {
                temp_filter_option.splice(i,1);
                bdeleted = true;
                break;
            }
        }
        if(bdeleted === false) {
            temp_filter_option.push(option);
        }
        console.log("cbFilterOption : after", temp_filter_option);
        props.cbUpdateOptionFilter(temp_filter_option);
    }

    

    // custering type --------------------------------------
    const onToggleClustering = (
            event: React.MouseEvent<HTMLElement>,
            newClustering: string,
        ) => {
        if(newClustering) {
            console.log("newClustering" - newClustering);
            props.cbUpdateCluteringRule(newClustering);
        }
    };			

    const onToggleShowHideSoldout = (
            event: React.MouseEvent<HTMLElement>,
            newValue: string,
        ) => {
            console.log("onToggleShowHideSoldout");
            console.log(newValue);
        if(newValue) {
            console.log("onToggleShowHideSoldout" - newValue);
            props.cbUpdateSoldoutHide(newValue);
        }
    };			


    const popup_page = useRef();

    const ShowFPPopup = () => {
        popup_page.current.style.display = "block";
        document.body.style.overflow = "hidden";        
    }
    const hideFPPopup = () => {
        popup_page.current.style.display = "none";
        document.body.style.overflow = "";
        props.cbPopupHide();
    }


    const OnClickDoneBtn = () => {
        hideFPPopup();
    }    

    
    return (
        <div>
            <div className='FilterPage_div' >
                <div className='FilterPage_summary' >
                    <div  className='filter_sum_div' onClick={ShowFPPopup}>
                        <div className={(props.filter_chain.length>0 | props.filter_option.length>0 |
                                        props.filter_region.length>0 | props.filter_star.length>0
                                        ?'filter_summary_div selected':'filter_summary_div')}>
                            <TuneIcon className='filter_summary_icon_svg' /> 
                             필터
                            {
                                (props.filter_chain.length>0 | props.filter_option.length>0 |
                                    props.filter_region.length>0 | props.filter_star.length>0
                                    ?<CheckIcon className='filter_selected_icon_svg' />:<></>)
                            }
                        </div>
                    </div>


                    <div className='clustering_button' >
                        <ToggleButtonGroup
                            value={props.clustering_rule}
                            color="primary"
                            exclusive
                            aria-label="clustering"
                            onChange={onToggleClustering}
                        >
                        <ToggleButton  style={{width:'100px', height:'40px', fontSize:'16px'}}  value="STAR">등급별</ToggleButton>
                        <ToggleButton  style={{width:'100px', height:'40px', fontSize:'16px'}} value="REGION">지역별</ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                   
                </div>
            </div>




            <div className='option_page_popup_bg' ref={popup_page}>
                <div className='option_page_popup'>
                    <div className='option_page_title_div' >
                        <div className='option_page_title'>필터링 옵션</div>
                        <div className='option_page_close_btn' onClick={hideFPPopup}><CloseIcon/></div>
                    </div>

                    <div className='option_page_content_div'>

                            <div className='option_page_option_title'>판매완료된 호텔</div>
                            <div className='option_page_option_content'>
                                <ToggleButtonGroup
                                        value={props.hide_soldout}
                                        color="primary"
                                        exclusive
                                        aria-label="hide_soldout"
                                        onChange={onToggleShowHideSoldout}
                                    >
                                    <ToggleButton  style={{width:'100px', height:'40px', fontSize:'16px'}}  value="SHOW">보이기</ToggleButton>
                                    <ToggleButton  style={{width:'100px', height:'40px', fontSize:'16px'}} value="HIDE">숨기기</ToggleButton>
                                </ToggleButtonGroup>
                                
                            </div>



                            <div className='option_page_option_title'>등급</div>
                            <div className='option_page_option_content'>
                                <FilterAllItem filter_list={props.filter_star} cbClick={cbFilterStarAll} />
                                {   
                                    props.available_stars.map((star, idx) => (
                                        <FilterItem key={star} filter={star} filter_list={props.filter_star} cbClick={cbFilterStar} />
                                    ))
                                }
                            </div>


                            <div className='option_page_option_title'>지역</div>
                            <div className='option_page_option_content'>
                                <FilterAllItem filter_list={props.filter_region} cbClick={cbFilterRegionAll} />
                                {   
                                    props.available_regions.map((region, idx) => (
                                        <FilterItem key={region} filter={region} filter_list={props.filter_region} cbClick={cbFilterRegion} />
                                    ))
                                }
                            </div>                            



                            <div className='option_page_option_title'>멤버십</div>
                            <div className='option_page_option_content'>
                                <ChainFilterAllItem filter_chain={props.filter_chain} cbClick={cbFilterChainAll} />
                                {   
                                    Array.from(PinoUtil.REGISTERED_CHAIN_IDS)
                                        .filter(([c_id,c_name]) => props.available_chains.includes(c_id))
                                        .map(([c_id,c_name])  => (
                                        <ChainFilterItem key={c_id} chain_id={c_id} chain_name={c_name} filter_chain={props.filter_chain} cbClick={cbFilterChain} />
                                    ))
                                }
                            </div>


                            <div className='option_page_option_title'>특징</div>
                            <div className='option_page_option_content'>
                                {
                                    props.available_options.map((option, idx) => (
                                        <OptionFilterItem key={option} option={option} filter_option={props.filter_option} cbClick={cbFilterOption} />
                                    ))
                                }
                            </div>
                    </div>


                    <div className='option_page_done_btn_div'>
                        <div className='option_page_done_btn' onClick={OnClickDoneBtn} >
                                완료
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );      
}





function ChainFilterAllItem(props) {
    var add_class = "off";
    if(props.filter_chain.length === 0) {
        add_class = "";
    }

    return (
        <span className={'filter_span '+(add_class)} onClick={() => {props.cbClick(props.chain_id);}}>전체</span>
    )
}

function ChainFilterItem(props) {
    var add_class = "off";
    for(var i=0;i<props.filter_chain.length;i++) {
        if(props.filter_chain[i] === props.chain_id) {
            add_class = "";
        }
    }

    return (
        <span className={'filter_span '+(add_class)} onClick={() => {props.cbClick(props.chain_id);}}>{props.chain_name}</span>
    )
}


function FilterAllItem(props) {
    var add_class = "off";
    if(props.filter_list.length === 0) {
        add_class = "";
    }

    return (
        <span className={'filter_span '+(add_class)} onClick={() => {props.cbClick(props.filter);}}>전체</span>
    )
}

function FilterItem(props) {
    var add_class = "off";
    for(var i=0;i<props.filter_list.length;i++) {
        if(props.filter_list[i] === props.filter) {
            add_class = "";
        }
    }

    return (
        <span className={'filter_span '+(add_class)} onClick={() => {props.cbClick(props.filter);}}>{props.filter}</span>
    )
}




function OptionFilterItem(props) {
    var add_class = "off";
    for(var i=0;i<props.filter_option.length;i++) {
        if(props.filter_option[i] === props.option) {
            add_class = "";
        }
    }

    return (
        <span className={'filter_span '+(add_class)} onClick={() => {props.cbClick(props.option);}}>{props.option}</span>
    )

}



export default FilterPage;
