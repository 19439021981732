//import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import CityList from './CityList/CityList';
import CityTable from './CityTable/CityTable';
import HotelList from './HotelList/HotelList';
import HotelPage from './HotelPage/HotelPage';
import { NavermapsProvider } from 'react-naver-maps';


function App() {
  return (
    <NavermapsProvider ncpClientId='8exqfm68py'>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path='/' element={<CityList />} />
            <Route path='/CityTable' element={<CityTable />} />
            <Route path='/:city_id' element={<HotelList />} />
            <Route path='/:city_id/:hotel_id' element={<HotelPage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </NavermapsProvider>
  );
}

export default App;
