import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from "react";
import axios from 'axios';
import {useLocation} from "react-router-dom";
import "./HotelPage.css";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarHalfRoundedIcon from '@mui/icons-material/StarHalfRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BedIcon from '@mui/icons-material/Bed';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LaptopIcon from '@mui/icons-material/Laptop';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Carousel from 'react-material-ui-carousel';
import * as PinoUtil from "../Common/PinoUtil";
import * as PinoText from "../Common/PinoTextRes";
import YouTubePlayerPage from "./YouTubePlayerPage";
import PriceChart from './PriceChart';
import { Container as NaverMapDiv, NaverMap, Marker as NaverMapMarker, useNavermaps } from 'react-naver-maps'
import { GoogleMap, useJsApiLoader, Marker as GoogleMapMarker } from '@react-google-maps/api';

import DatePickPage from "../HotelList/DatePickPage";



    
const map_div_style = {
    height: '200px',  
    margin: '20px'
};




function HocancePage(props) {    

    const location = useLocation();
    //console.log(location);

    const [hotel_id, setHotelID] = useState(0);
    const [hotel_json, setHotelJson] = useState({});
    const [hotel_additional_info_phone, setHotelAdditionalInfoPhoto] = useState("");
    const [hotel_additional_info_website, setHotelAdditionalInfoWebsite] = useState("");
    const [hotel_google_review_link, setHotelGoogleReviewLink] = useState("");
    const [cur_tab, setCurTab] = useState("Price");
    const [review_json, setReviewJson] = useState([]);
    const [youtube_json, setYoutubeJson] = useState([]);
    const [youtube_show_count, setYoutubeShowCount] = useState(5);
    const [YouTubePlayingID, setYouTubePlayingID] = useState("");
    const [bIsKorea, setIsKorea] = useState(true);
    const [stickyClass, setStickyClass] = useState('relative');
    const [price, setPrice] = useState(PinoText.PINO_TEXT_KO.SEARCHING_PRICE);
    const [avg_price, setAvgPrice] = useState(0);
    //const [price_comp_msg, setPriceCompMsg] = useState('');
    //const [price_comp_class, setPriceCompClass] = useState('');
    const [priceLink, setPriceLink] = useState('');
    const [roomTimme, setRoomTime] = useState('');
    const [features, setFeatures] = useState([]);
    const [rating_avg, setRatingAvg] = useState(0.0);
    const [rating_count, setRatingCount] = useState(0);

    
    const ref_Gmap = useRef();

    function LoadHotelJson() {
        var hotel_id = location.pathname.split('/')[2];
        setHotelID(hotel_id);
        var city_name = location.pathname.split('/')[1];
        console.log("LoadHotelJson", hotel_id, city_name);

        axios.get('../data/'+city_name+'.csv')
            .then(function(response) {
                //console.log("sss", response.data);
                var result = response.data;
        
                const rows = result.split("\r\n");
                for(let i = 0; i < rows.length; i++){
                    //console.log(rows[i]);

                    var temp_hotel_json = PinoUtil.PINO_ParseHotelData(rows[i])
                    if(temp_hotel_json.hotel_id === hotel_id) {
                        //hotel_json
                        console.log("LoadHotelJson - matched", temp_hotel_json);
                        setHotelJson(temp_hotel_json);

                        // features 
                        var temp_features = [];                    
                        temp_features.push(temp_hotel_json.hotel_star);
                        var year_text = PinoUtil.PINO_getHotelYearText(temp_hotel_json);
                        if(year_text != "") {
                            temp_features.push(year_text);
                        }
                        setFeatures(temp_features);

                        // load dynamic datas
                        LoadGoogleReview(temp_hotel_json.google_id);
                        LoadYoutubeVideo(temp_hotel_json.hotel_id, temp_hotel_json.hotel_name);
                        HD_SearchPrice(hotel_id);
                        LoadAvgPrice(hotel_id);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error : LoadHotelJson");
            })
    }



    useEffect(()=> {
        console.log("execute once");
        LoadHotelJson();

        window.addEventListener('scroll', onScrollPage);

    }, []);


    const onScrollPage = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 600 ? setStickyClass('fixed') : setStickyClass('relative');

            //TODO: 현재 스크롤 위치에 따라 탭 메뉴 포커스 이동 
            var top_pos_price = document.getElementById('HD_section_price').offsetTop;
            var top_pos_review = document.getElementById('HD_section_review').offsetTop;
            var top_pos_youtube = document.getElementById('HD_section_youtube').offsetTop;
            var top_pos_info = document.getElementById('HD_section_info').offsetTop;

            var cursor = windowHeight + (window.innerHeight /2);
            //console.log(cursor, top_pos_price, top_pos_review, top_pos_youtube, top_pos_info);

            if(cursor < top_pos_review) {
                setCurTab('Price');
            }
            else if(cursor < top_pos_youtube) {
                setCurTab('Review');
            }
            else if(cursor < top_pos_info) {
                setCurTab('YouTube');
            }
            else {
                setCurTab('Info');
            }
                        
        }
    };    

    const OnTabChanged = (event, newValue) => {
        setCurTab(newValue);
        console.log("OnTabChanged : ", newValue);

        var element = null;
        if(newValue === 'Info') {
            element = document.getElementById('HD_section_info');
        }
        else if(newValue === 'Price') {
            element = document.getElementById('HD_section_price');
        }
        else if(newValue === 'Review') {
            element = document.getElementById('HD_section_review');
        }
        else if(newValue === 'YouTube') {
            element = document.getElementById('HD_section_youtube');
        }
        
        if (element != null) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
        
    }

    const CloseCurPage = () => {
        window.open("about:blank", "_self");
        window.close();
    }





    // --------------------------------- Google Place -----------------------------------------------------------

    function LoadGoogleReview(google_place_id) {
        console.log("LoadGoogleReview", google_place_id);

        var place_api_url = PinoUtil.PINO_API_URL+'/place?place_id='+google_place_id;
        //console.log(place_api_url);
        
        axios.get(place_api_url)
            .then(function(response) {
                var result = response.data;
                console.log("CB - LoadGoogleReview", result);

                if(result.result == "OK") {
                    if( PinoUtil.PINO_IsExpiredDate(result.place.updated_datetime) == false) {      
                        setReviewJson([...result.place.place_details.reviews]);
                        setHotelAdditionalInfoPhoto(result.place.place_details.phone);
                        setHotelAdditionalInfoWebsite(result.place.place_details.website);
                        setHotelGoogleReviewLink(result.place.place_details.google_url);
                        setRatingAvg(result.place.place_details.rating);
                        setRatingCount(result.place.place_details.rating_count);
                    }
                    else {
                        //HD_SearchGoogleData();
                        SearchGoogleReview(google_place_id);
                    }
                }
                else if(result.result == "NO_RESULT") {
                    SearchGoogleReview(google_place_id);
                }
            })
            .catch(function(error) {
                console.log("Error! LoadGoogleReview",error);
            })
    }

    function SearchGoogleReview(google_place_id) {
        console.log("SearchGoogleReview ",google_place_id);

        ref_Gmap.current.SearchGooglPlaceDetail(google_place_id);
    }

    function cbGooglePlaceSearch(place_data) {
        console.log("cbGooglePlaceSearch",place_data);

        setReviewJson([...place_data.reviews]);
        setHotelAdditionalInfoPhoto(place_data.phone);
        setHotelAdditionalInfoWebsite(place_data.website);
        setHotelGoogleReviewLink(place_data.google_url);
        setRatingAvg(place_data.rating);
        setRatingCount(place_data.rating_count);
        
        PostPlaceData(place_data);
    }

    function PostPlaceData(place_data) {
        console.log("PostPlaceData",place_data);

        var req_url = PinoUtil.PINO_API_URL + "/place";
        var body_data = {};
        body_data.place_detail = place_data;
        axios.post(req_url, body_data)
            .then(function(response) {
                var result = response.data;
                console.log("CB - PostPlaceData", result);
            })
            .catch(function(error) {
                console.log("Error! PostPlaceData",error);
            })
    }


    // ---------------------------------- YouTube ----------------------------------------------------------------------------------

    function LoadYoutubeVideo(hotel_id, hotel_name) {
        console.log("LoadYoutubeVideo", hotel_id);

        var video_api_url = PinoUtil.PINO_API_URL+'/video?hotel_id='+hotel_id;
        //console.log(video_api_url);
        
        axios.get(video_api_url)
            .then(function(response) {
                var result = response.data;
                console.log("CB - LoadYoutubeVideo", result);

                if(result.result == "OK") {
                    if( PinoUtil.PINO_IsExpiredDate(result.video_data.updated_datetime) == false) {      
                        setYoutubeJson([...result.video_data.youtube_videos]);
                    }
                    else {
                        SearchYoutube(hotel_id, hotel_name);
                    }
                }
                else if(result.result == "NO_RESULT") {
                    SearchYoutube(hotel_id, hotel_name);
                }                

            })
            .catch(function(error) {
                console.log("Error! LoadYoutubeVideo",error);
            })
    }


    function SearchYoutube(hotel_id, hotel_name) {
        if(hotel_name == undefined) {
            console.error("hotel_name == undef", hotel_json);
            return;
        }
        
        console.log("★★★★★★★★★★ YT search : 50");
        var req_url = PinoUtil.YOUTUBE_API_URL+ "/search?key=" + PinoUtil.YOUTUBE_API_KEY + "&part=id,snippet&type=video&maxResults=50&q="+encodeURI(hotel_name);
        console.log(req_url);

        
        axios.get(req_url)
            .then(function(response) {
                var result = response.data;
                console.log("CB - SearchYoutube", result);
                var pino_videos = PinoUtil.PINO_convertYoutubeVideoToPinoVideo(result);
                console.log(pino_videos);
                setYoutubeJson([...pino_videos]);
                PostVideoData(hotel_id, pino_videos);
            })
            .catch(function(error) {
                console.log("Error! SearchYoutube",error);
            })    
    }    


    function PostVideoData(hotel_id, video_obj) {
        console.log("PostVideoData :", hotel_id, video_obj);

        var req_url = PinoUtil.PINO_API_URL + "/video";
        var body_data = {};
        body_data.hotel_id = hotel_id.toString();
        body_data.youtube_videos = video_obj;
        axios.post(req_url, body_data)
            .then(function(response) {
                var result = response.data;
                console.log("CB - PostVideoData", result);
            })
            .catch(function(error) {
                console.log("Error! PostVideoData",error);
            })
    
        return;
    }

    
    function ShowMoreYouTube() {
        var next_count = youtube_show_count+5;
        if(next_count > youtube_json.length) {
            next_count = youtube_json.length;
        }
        if(next_count != youtube_show_count) {
            setYoutubeShowCount(youtube_show_count+5);
            console.log("youtube_show_count increased");
        }
    }


    function PlayYoutubeVideo(v_id) {
        console.log("PlayYoutubeVideo",v_id);
        setYouTubePlayingID(v_id);
    }

    function CloseYoutubeVideo() {
        console.log("CloseYoutubeVideo");
        setYouTubePlayingID("");
    }

    const OnDatePickDone = () => {
        console.log("OnDatePickDone");

        HD_SearchPrice();
    }

    const HD_SearchPrice = (hid) => {
        console.log("HD_SearchPrice");

        if(hid == undefined) {
            if('hotel_id' in hotel_json) {
                hid = hotel_json.hotel_id;
            }
        }
        if(hid == undefined) {
            console.log("HD_SearchPrice - empty hid");
            return;
        }

        // 1. query price
        var saved_td = PinoUtil.PINO_loadTourDate(); 
        //var req_url = 'https://hj26v621uh.execute-api.ap-northeast-2.amazonaws.com/prod/hotel/price?st_date='+saved_td.st_date+'&end_date='+saved_td.end_date
        var req_url = PinoUtil.PINO_API_URL + '/hotel/price?st_date='+saved_td.st_date+'&end_date='+saved_td.end_date
                        +'&num_adult='+saved_td.num_adult+'&num_children='+saved_td.num_children
                        +'&lang_country=ko-kr&currency=KRW&hotel_ids='+hid;
        console.log("SearchPrice", req_url);

        
        axios.get(req_url)
            .then((result)=>{ 
                var resp = result.data;
                console.log("HD_SearchPrice result", resp);               
                var temp_price =  PinoText.PINO_TEXT_KO.NO_ROOM_LEFT;
                var temp_price_link =  PinoUtil.AGODA_LINK_URL+hotel_json.hotel_id;
                var temp_room_type = '';

                if('results' in resp) {
                    if(resp.results.length == 1) {                                
                        temp_price = resp.results[0].dailyRate.toLocaleString() +'원';
                        temp_price_link = resp.results[0].landingURL;
                        temp_room_type = resp.results[0].roomtypeName;
                    }
                }
                
                setPrice(temp_price);
                setPriceLink(temp_price_link);
                setRoomTime(temp_room_type);
            })
            .catch(() => {});        

    }


    // avg price csv -------------------------------------------------------------------
    const LoadAvgPrice = (h_id) => {
        console.log("LoadAvgPrice");

        fetch('/data/price/h_price_avg.csv')
        .then(response => response.text())
        .then(data => {
            // Do something with the parsed CSV data
            const rows = data.trim().split('\n');
            const headers = rows.shift().split(',');
            //console.log(headers);
            //console.log(rows);
            for(var i=0;i<rows.length;i++) {
                var cells = rows[i].split(',');
                if(cells[0] == h_id) {
                    setAvgPrice(cells[1]);
                    break;
                }
            }
        })
        .catch(error => {
            // Handle error
            console.error(error);
        });
    }

    const ComposeAvgPriceMsg = () => {
        if(price.indexOf('원') > 0) {
            var cur_price = Number(price.replace("원", '').replaceAll(',',''));
            //console.log(cur_price);
            var percentage = (cur_price/avg_price*100 -100).toFixed(0);

            if(percentage> 10) {
                return "평소 보다 "+percentage+"% 더 비쌈";
            }
            else if(percentage< -10) {
                return "평소 보다 "+percentage+"% 저렴";
            }
            else {
                return "평소 가격과 비슷함";
            }
        }
        return "";
    }

    const ComposeAvgPriceClass = () => {
        if(price.indexOf('원') > 0) {
            var cur_price = Number(price.replace("원", '').replaceAll(',',''));
            //console.log(cur_price);
            var percentage = (cur_price/avg_price*100 -100).toFixed(0);

            if(percentage> 10) {
                return "price_comp_msg expensive";
            }
            else if(percentage< -10) {
                return "price_comp_msg cheap";
            }
            else {
                return "price_comp_msg moderate";
            }
        }
        return "price_comp_msg ";
    }


    return (
        <div id='hotel_detail_page'>

            <div className='HD_main_div'>
                <div className='HD_main_img_div'>
                    <Carousel autoPlay={false} animation={"fade"} duration={300} indicators={true} navButtonsAlwaysVisible={false}  >
                        <ImageItem img_url={ PinoUtil.ChangeAgodaPhotoBigSize(hotel_json.photo_1)} />
                        <ImageItem img_url={ PinoUtil.ChangeAgodaPhotoBigSize(hotel_json.photo_2)} />
                        <ImageItem img_url={ PinoUtil.ChangeAgodaPhotoBigSize(hotel_json.photo_3)} />
                        <ImageItem img_url={ PinoUtil.ChangeAgodaPhotoBigSize(hotel_json.photo_4)} />
                        <ImageItem img_url={ PinoUtil.ChangeAgodaPhotoBigSize(hotel_json.photo_5)} />
                    </Carousel>
                </div>

                <div className='HD_main_title_div'>

                    <div className='HD_features'>
                        {
                            features.map((ft, i) => (
                                <span key={i} className='HD_feature_span'> {ft} </span>
                            ))
                        }
                    </div>

                    <div className={`hotel_detail_main_title ${stickyClass}`}>
                        <div className='hotel_detail_main_title_inner'>
                            <div id='back_icon' onClick={CloseCurPage}><ArrowBackIcon /></div>
                            <div id='HD_title_text'> {hotel_json.hotel_name} </div>
                        </div>
                    </div>

                    <div id='HD_main_rating_sum'>
                        {[...Array(5)].map((e, i) =>
                            rating_avg> i?
                                (rating_avg> i+1?<StarRoundedIcon key={i}/>:<StarHalfRoundedIcon key={i}/>)
                                :<StarOutlineRoundedIcon key={i}/>
                        ) }
                        <span id='HD_rating_avg'>{rating_avg}</span>
                        <span id='HD_rating_count'> ({rating_count}개 리뷰)</span>
                    </div>


                        <div className='HD_DP_div'>
                            <DatePickPage cbFinish={OnDatePickDone}  bShowFull={true} />
                        </div>
                        <div id='HD_price_result_div'   onClick={()=>{window.open(priceLink)} }  >
                            <div id="HD_roomtype" >{roomTimme}</div>
                            <div  id="HD_price" >{price}</div>
                            <div id='HD_price_compare_msg' className={ComposeAvgPriceClass()}> {ComposeAvgPriceMsg()}   </div>
                        </div>
                        <div id='HD_main_order'  onClick={()=>{window.open(priceLink)} }>객실 예약하기</div>                            

                    {/*
                    <div id='HD_main_order_div'>

                    </div>
                    */}
                </div>
            </div>

            <div  className={`HD_tab_menu_div ${stickyClass}`}>
                <div className='HD_tab_menu_div_inner'> 
                    <Tabs value={cur_tab} onChange={OnTabChanged} variant="fullWidth" aria-label='hotel detail tabs' 
                        textColor="inherit"    
                        TabIndicatorProps={{
                            style: {
                            backgroundColor: "#2346ff"
                            }
                      }} >
                        <Tab label={<span className='TabItem'>가격</span>} value="Price" style={{ color: cur_tab=='Price'?'#2346ff':'#444'}} />
                        <Tab label={<span className='TabItem'>리뷰</span>} value="Review" style={{ color: cur_tab=='Review'?'#2346ff':'#444'}} />
                        <Tab label={<span className='TabItem'>동영상</span>} value="YouTube" style={{ color: cur_tab=='YouTube'?'#2346ff':'#444'}} />
                        <Tab label={<span className='TabItem'>정보</span>} value="Info" style={{ color: cur_tab=='Info'?'#2346ff':'#444'}} />
                    </Tabs>
                </div>
            </div>








            <div className='HD_section_div' id='HD_section_price'>
                <PriceChart h_id={hotel_id}  /> 
            </div>





            <div className='HD_section_div' id='HD_section_review'>
                <div  className='HD_section_title'>리뷰</div>
                {
                    review_json.map((rv, i) => (
                        <ReviewItem key={i} review_data={rv}  />
                    ))
                }
                <div className='detail_see_more' onClick={()=>{window.open(hotel_google_review_link)} } >더 보기</div>
            </div>



            <div className='HD_section_div'  id='HD_section_youtube'>
                <div  className='HD_section_title'>동영상</div>
                {
                    youtube_json.slice(0,youtube_show_count).map((yt, i) => (
                        <YouTubeItem key={i} youtube_data={yt} youtubePlayFunc={PlayYoutubeVideo}  />
                    ))
                }
                <div className='detail_see_more' onClick={ShowMoreYouTube}>더 보기</div>
            </div>
            


            <div className='HD_section_div'  id='HD_section_info'>
                <div className='HD_section_title'>정보</div>
                <div className='HD_info_line'><div className='HD_info_icon'><BedIcon/></div><div className='HD_info_text'>전체 {hotel_json.number_of_rooms} 객실</div></div> 
                <div className='HD_info_line'><div className='HD_info_icon'><LoginIcon/></div><div className='HD_info_text'>체크인: {hotel_json.checkin_time}</div></div> 
                <div className='HD_info_line'><div className='HD_info_icon'><LogoutIcon/></div><div className='HD_info_text'>체크아웃: {hotel_json.checkout_time}</div></div> 
                <div className='HD_info_line'><div className='HD_info_icon'><LocalPhoneIcon/></div><div className='HD_info_text'>{hotel_additional_info_phone}</div></div> 
                <div className='HD_info_line'><div className='HD_info_icon'><LocationOnIcon/></div><div className='HD_info_text'>{hotel_json.address}</div></div> 
                <div className='HD_info_line'><div className='HD_info_icon'><LaptopIcon/></div>
                    <div className='HD_info_text underline'  onClick={()=>{window.open(hotel_additional_info_website)} } >
                        {hotel_additional_info_website}
                    </div>
                </div> 
                {bIsKorea? 
                    <NaverMapDiv style={map_div_style} >
                        <NMap lat={hotel_json.lat} lng={hotel_json.lng}  />
                    </NaverMapDiv>
                    :
                    <></> 
                }
                <div  style={{display: bIsKorea ? 'none' : 'block' }} >
                    <GMap lat={hotel_json.lat} lng={hotel_json.lng} ref={ref_Gmap} place_id={hotel_json.google_place_id} cbFunction={cbGooglePlaceSearch}/>
                </div>
                
                <div className="HD_order"  onClick={()=>{window.open(priceLink)} } >
                    객실 예약하기
                </div>
            </div>




            {YouTubePlayingID === ''?
                <></>:
                <YouTubePlayerPage video_id={YouTubePlayingID} closeCB={CloseYoutubeVideo} />
            } 
            

        </div>
    );
}

function NMap(props) {
    // instead of window.naver.maps
    const navermaps = useNavermaps()

    const center = {
        lat: (props.lat!=undefined? parseFloat(props.lat) : 37.3595704),
        lng: (props.lng!=undefined? parseFloat(props.lng) : 127.105399)
    };

    //console.log("NMap", center);
    
    return (
      <NaverMap
        defaultCenter={center}
        defaultZoom={15}
      >
        <NaverMapMarker
          defaultPosition={center}
        />
      </NaverMap>
    )
}




//const Google_map_req_libs = ['places'];
const GMap = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        SearchGooglPlaceDetail
    }))

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: PinoUtil.YOUTUBE_API_KEY,
        libraries: PinoUtil.Google_map_req_libs,
        language: 'ko',
    });

        
    const MapOptions = {
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',     // 'none', 'cooperative', 'greedy'
        styles: [
            { "featureType": "administrative", "elementType": "geometry",    "stylers": [      {   "visibility": "off"     }    ]  },
            { "featureType": "administrative.land_parcel", "elementType": "labels",    "stylers": [      {        "visibility": "off"      }    ]  },
            { "featureType": "poi",    "stylers": [      {        "visibility": "off"      }    ]  },
            { "featureType": "road",    "elementType": "labels.icon",    "stylers": [      {        "visibility": "off"      }    ]  },
            { "featureType": "road.local",    "elementType": "labels",    "stylers": [      {        "visibility": "off"      }    ]  },
            { "featureType": "transit",    "stylers": [      {        "visibility": "off"      }    ]  }
        ]
    };


    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);        
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const center = {
        lat: (props.lat!=undefined? parseFloat(props.lat) : -3.745),
        lng: (props.lng!=undefined? parseFloat(props.lng) : -38.523)
    };
    //console.log("GMap",center);

    function SearchGooglPlaceDetail(google_place_id) {
        console.log("SearchGooglPlaceDetail", google_place_id );

        try {
            let request = {
                placeId: google_place_id,
                fields: ["name", "formatted_address", "place_id", "geometry", "address_components","adr_address","business_status",
                        "icon","photos","plus_code","types","url","utc_offset_minutes","vicinity",
                        "formatted_phone_number","international_phone_number","opening_hours","website",   // SKU: Contact Data / $3/1000call)
                        "price_level","rating","reviews","user_ratings_total"		]				// SKU: Atmosphere Data / $5/1000call)
            };
        
            console.log("SearchGooglPlaceDetail ");

            let service = new window.google.maps.places.PlacesService(map);
            console.log(service);
            console.log(map);


            console.log("★★★★★★★★★★ CHARGED : GET DETAILS = 25");
            service.getDetails(request, (place_raw, status) => {
                //console.log("jkjkjk",status, place_raw);
                if (status === window.google.maps.places.PlacesServiceStatus.OK &&	place_raw && place_raw.geometry && place_raw.geometry.location) {
                    console.log("SearchGooglPlaceDetail done : ",place_raw);
                    
                    var place = PinoUtil.PINO_convertGooglePlaceToPinoPlace(place_raw);
                    console.log(place);
                    props.cbFunction(place);
                }
                else {
                    console.log("ERR! SearchGooglPlaceDetail ");
                }
            });
        }
        catch(error) {
            console.log("SearchGooglPlaceDetail ERROR : ", error)
        }

    }
      
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={map_div_style}
            center={center}
            zoom={13}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={MapOptions}
        >
            <GoogleMapMarker
                position={center}
            />
        </GoogleMap>
    ) : <></>
});



function ImageItem(props) {
    return (
        <div className='detail_hotel_img_div'>
            <img className='detail_hotel_img' src={props.img_url} alt=''/>
        </div>
    )
}

function ReviewItem(props) {
    return (
        <div className='hd_review_div'>
            <div className='detail_info_rating_author'>
                <img className='detail_info_rating_author_img' src={props.review_data.author_img} />
                <span className='detail_info_rating_author_name'>{props.review_data.author_name}</span>
            </div>
            <div className='detail_info_rating'>
                <span className='detail_info_rating_point'>
                    {/*
                    {[...Array(props.review_data.rating)].map((e, i) => <StarRoundedIcon key={i}/>)}
                     */}
                    {[...Array(5)].map((e, i) =>
                        props.review_data.rating> i?
                            <StarRoundedIcon key={i}/>
                            :<StarOutlineRoundedIcon key={i}/>
                    ) }
                </span>
                <span className='detail_info_rating_time'>{ PinoUtil.PINO_convertEpochToDisplayTime(props.review_data.time)}</span>
            </div>
            <div className='detail_info_rating_text'>{props.review_data.text}</div>
        </div>
    )
}


function YouTubeItem(props) {
    return (
        <div className='li_detail_video_item' onClick={()=>props.youtubePlayFunc(props.youtube_data.video_id)}>
            <div className='li_detail_video_img'>
                <img className='img_detail_video_thumb' src={props.youtube_data.thumb} />
                <div className='img_detail_video_play_icon' ></div> 
            </div>
            <div className='li_detail_video_text'>
                <div className='li_detail_video_title'>{props.youtube_data.title}</div>
                <div className='li_detail_video_ch_name'>{props.youtube_data.ch_name}</div>
                <div className='li_detail_video_date_fb'>
                    <div className='li_detail_video_date'>{props.youtube_data.upload_date.substring(0,10)}</div>
                </div>
            </div>
        </div>
    )
}

export default HocancePage; 