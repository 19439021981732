
export const AGODA_LINK_URL = 'https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1900596&hl=ko-kr&hid='; 

//export const PINO_API_URL = 'https://hj26v621uh.execute-api.ap-northeast-2.amazonaws.com/prod';
//export const PINO_API_URL = 'https://d2q1hc1r7ctewq.cloudfront.net';
//export const PINO_API_URL = 'https://api.hocancesitga.com';
export const PINO_API_URL = 'https://api.pricemate.link';


const API_CACHE_DAYS = 100;    // 10 일 = 86400초 x 10일 x 1000 msec

export const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3';
export const YOUTUBE_API_KEY = 'AIzaSyAtpGDKBEoXaNjRJ_sTeykecnmxFhkadk4';


const HOTEL_RENOVATION_YEAR_LIMIT = 2018;
const HOTEL_OPEN_YEAR_LIMIT = 2018;


export const Google_map_req_libs = ['places'];

export const REGISTERED_CHAIN_IDS = new Map([
    //[-1, "전체"],
    [14, "힐튼"],
    [21, "메리어트"],
    [15, "하얏트"],
    [16, "IHG"],
    [3, "아코르"],
    [3580, "신라"],
    [808, "롯데"],
    [0, "기타"]
])



export const PINO_COUNTRY_LIST = new Map([
    ["KR", { country_name:"대한민국", cities:['Seoul','Gyeonggi','Jeju','Busan','Gangwon']} ],
    ["VN", { country_name:"베트남", cities:['Danang']} ],
    ["JP", { country_name:"일본", cities:['Osaka']} ],
    ["MP", { country_name:"북마리나제도", cities:['Saipan']} ],
]);


export const PINO_CITY_LIST = new Map([
    ["Seoul", { city_name:"서울", default_clustering:"STAR", city_img:"https://live.staticflickr.com/4809/45979304321_c391d65f72_q.jpg"} ],
    ["Gyeonggi", { city_name:"경기/인천", default_clustering:"REGION", city_img:"https://cdn.pixabay.com/photo/2016/10/20/07/48/songdo-incheon-korea-1754844_960_720.jpg"} ],
    ["Jeju", { city_name:"제주", default_clustering:"STAR", city_img:"https://live.staticflickr.com/5134/5408905962_91c7ac21af_q.jpg"} ],
    ["Busan", { city_name:"부산", default_clustering:"STAR", city_img:"https://live.staticflickr.com/65535/27874092583_642956ef34_q.jpg"} ],
    ["Gangwon", { city_name:"강원도", default_clustering:"REGION", city_img:"https://media.istockphoto.com/photos/panoramic-view-of-emerald-beach-from-hajodae-observatory-in-yangyang-picture-id1291876867?b=1&k=20&m=1291876867&s=170667a&w=0&h=iqvCYW_R7ogiqCNADTFssv73oUAMPLd4oti9voMac_o="} ],
    ["Danang", { city_name:"다낭", default_clustering:"REGION", city_img:"https://live.staticflickr.com/65535/48535948346_b97a15cb3f_q.jpg"} ],
    ["Osaka", { city_name:"오사카", default_clustering:"REGION", city_img:"https://live.staticflickr.com/1539/23996699151_6fb2de7cd7_q.jpg"} ],
    ["Saipan", { city_name:"사이판", default_clustering:"STAR", city_img:"https://live.staticflickr.com/574/22750458592_45f66b88e9_q.jpg"} ]    
]);




export function PINO_getHotelYearText(hd) {

    var ret = "";
    if(hd.year_renovated !== "" && parseInt(hd.year_renovated) > HOTEL_RENOVATION_YEAR_LIMIT) {
        ret = hd.year_renovated+"년 리모델링";
    }
    else if(hd.year_opened !== ""  && parseInt(hd.year_opened) > HOTEL_OPEN_YEAR_LIMIT) {
        ret = hd.year_opened+"년 개장";
    }

    return ret;
}

export function ChangeAgodaPhotoBigSize(url) {
    // https://pix5.agoda.net/hotelimages/24333639/0/c2aa1c269a9920d0cb1849a72a4ef69c.jpg?s=312x&ca=20&ce=0
    if(url !== undefined) {
        return url.replace('s=312x', 's=924x');
    }
    else {
        return "";
    }
}




export function PINO_ParseCityInfoData(row) {
    let values = row.split("	");
    
    var city_json = {};
    city_json.id = values[0];
    city_json.name = values[3];
    city_json.img = values[7];
            
    return city_json;
}




export function PINO_ParseHotelData(row) {
    let values = row.split("	");
    
    var hotel_json = {};
    hotel_json.hotel_id = values[0];
    hotel_json.chain_id = parseInt(values[1]);
    if( REGISTERED_CHAIN_IDS.has(hotel_json.chain_id) === false ) {
        hotel_json.chain_id = 0;
    }
    hotel_json.hotel_name = values[2];
    hotel_json.lat = parseFloat(values[3]);
    hotel_json.lng = parseFloat(values[4]);
    hotel_json.checkin_time = values[5];
    hotel_json.checkout_time = values[6];
    hotel_json.photo_1 = values[7];
    hotel_json.photo_2 = values[8];
    hotel_json.photo_3 = values[9];
    hotel_json.photo_4 = values[10];
    hotel_json.photo_5 = values[11];
    hotel_json.rates_from = values[12];
    hotel_json.number_of_reviews = values[13];
    hotel_json.rating_average = values[14];
    hotel_json.number_of_floors = values[15];
    hotel_json.year_opened = values[16];
    hotel_json.year_renovated = values[17];
    hotel_json.number_of_rooms = values[18];
    hotel_json.hotel_star = values[19] + "성급";
    hotel_json.address = values[20];
    hotel_json.google_id = values[21];
    hotel_json.region = values[22];
    hotel_json.options = values[23];

        
    //hotel_json.price = "가격 검색중";
    //hotel_json.price_link = AGODA_LINK_URL+hotel_json.hotel_id;

    return hotel_json;
}

export function PINO_IsExpiredDate(time_str) {
    var elap_days = (Date.now() - Date.parse(time_str)) / 86400000;
    if( elap_days > API_CACHE_DAYS) {
        console.log("PINO_IsExpiredDate - expired", elap_days);
        return true;
    }

    console.log("PINO_IsExpiredDate - not expired", elap_days);
    return false;
}




export function PINO_convertYoutubeVideoToPinoVideo(youtube_video_obj) {

    var pino_videos = [];
    for(var i=0;i<youtube_video_obj.items.length;i++) {
        var item = youtube_video_obj.items[i];
        
        var parsed_item = {};
        parsed_item.video_id = item.id.videoId;
        parsed_item.title = item.snippet.title;
        parsed_item.ch_id = item.snippet.channelId;
        parsed_item.ch_name = item.snippet.channelTitle;
        parsed_item.desc = item.snippet.description;
        parsed_item.upload_date = item.snippet.publishedAt;
        parsed_item.thumb = item.snippet.thumbnails.medium.url;

        pino_videos.push(parsed_item);
    }

    return pino_videos;

}


export function PINO_convertGooglePlaceToPinoPlace(google_place_obj) {
    var pino_place = {};
    pino_place.place_id = google_place_obj.place_id;
    pino_place.name = google_place_obj.name;
    pino_place.types = google_place_obj.types;
    pino_place.lat = google_place_obj.geometry.location.lat();
    pino_place.lng = google_place_obj.geometry.location.lng();
    pino_place.address = google_place_obj.formatted_address;
    pino_place.google_url = google_place_obj.url;
    if("formatted_phone_number" in google_place_obj) {
        pino_place.phone = google_place_obj.formatted_phone_number;
    }
    if("website" in google_place_obj) {
        pino_place.website = google_place_obj.website;
    }
    if("photos" in google_place_obj) {
        pino_place.photos = [];
        for(var i=0;i<1;i++) {
            var photo_item = {};
            photo_item.url = google_place_obj.photos[i].getUrl();
            console.log("★★★★★★★★★★ CHARGED : GET URL = 7")
            photo_item.html_attributions = google_place_obj.photos[i].html_attributions[0];
            pino_place.photos.push(photo_item);
        }
    }
    if("opening_hours" in google_place_obj) {
        pino_place.opening_hours = [];
        for( i=0;i<google_place_obj.opening_hours.weekday_text.length;i++) {
            pino_place.opening_hours.push(google_place_obj.opening_hours.weekday_text[i]);
        }
    }
    if("rating" in google_place_obj) {
        pino_place.rating = google_place_obj.rating;
    }
    if("user_ratings_total" in google_place_obj) {
        pino_place.rating_count = google_place_obj.user_ratings_total;
    }
    else {
        pino_place.rating_count = 0;
    }
    if("reviews" in google_place_obj) {
        pino_place.reviews = [];
        for( i=0;i<google_place_obj.reviews.length;i++) {
            var rv_item = {};
            rv_item.text = google_place_obj.reviews[i].text;
            rv_item.rating = google_place_obj.reviews[i].rating;
            rv_item.author_name = google_place_obj.reviews[i].author_name;
            rv_item.author_url = google_place_obj.reviews[i].author_url;
            rv_item.author_img = google_place_obj.reviews[i].profile_photo_url;
            rv_item.time = google_place_obj.reviews[i].time;
            rv_item.lang = google_place_obj.reviews[i].language;
            pino_place.reviews.push(rv_item);
        }
    } 
    if("address_components" in google_place_obj) {
        for( i=0;i<google_place_obj.address_components.length;i++) {
            if(google_place_obj.address_components[i].types.indexOf('country')>=0) {
                pino_place.cc = google_place_obj.address_components[i].short_name;
                break;
            }
        }
    }

    pino_place.saved_timestamp = Date.now();
    
    return pino_place;
}



export function PINO_DisplayDateText(date) {
    if(date == undefined) {
        return "";
    }
    return (date.getMonth()+1) + "월" + date.getDate() + "일";
}


export function PINO_convertEpochToDisplayTime(epoch_time) {

    var input = epoch_time;
    if(input < 10000000000) {
        input *= 1000;
    }

    var date = new Date(input);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    return year+"-"+month+"-"+day;
}


/*
const PinoUtil = {
    AGODA_LINK_URL : 'https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1900596&hl=ko-kr&hid='

    
};
  
export default PinoUtil;
*/






const PINO_TOUR_DATE_KEY = 'pino_tour_date';

export function PINO_saveTourDate(st_date, end_date, num_adult = 2, num_children = 0) {
    var save_item = {};
    save_item.st_date = st_date;
    save_item.end_date = end_date;
    save_item.num_adult = num_adult;
    save_item.num_children = num_children;
    localStorage.setItem(PINO_TOUR_DATE_KEY, JSON.stringify(save_item));
}


export function PINO_loadTourDate() {
    var ret_data = {};
    var today = new Date();

    var td = localStorage.getItem(PINO_TOUR_DATE_KEY);
    if(td != null) {
        ret_data = JSON.parse(td);
        var saved_st_date = new Date(ret_data.st_date);
        if(saved_st_date > today & 'num_adult' in ret_data & 'num_children' in ret_data) { //  saved date is valid
            return ret_data;
        }
        else {      // saved date is expired
            localStorage.removeItem(PINO_TOUR_DATE_KEY);
        }
    }

    // return new default today date
    var today_str =  today.getFullYear() +"-"+ ("0" + (1 + today.getMonth())).slice(-2) +"-"+ ("0" + today.getDate()).slice(-2);
    var tomorrow = new Date(today.setDate(today.getDate()+1));
    var tomorrow_str =  tomorrow.getFullYear() +"-"+ ("0" + (1 + tomorrow.getMonth())).slice(-2) +"-"+ ("0" + tomorrow.getDate()).slice(-2);
    ret_data.st_date = today_str;
    ret_data.end_date = tomorrow_str;  
    ret_data.num_adult = 2;  
    ret_data.num_children = 0;  
    return ret_data;    
}


export function PINO_getDispDateText(date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    // yyyy-mm-dd 형식의 문자열로 변환합니다.
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}