import React, {useState, useEffect} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ko'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './PriceChart.css';
import * as PinoUtil from "../Common/PinoUtil";


// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
moment.locale('ko');
const localizer = momentLocalizer(moment) // or globalizeLocalizer

//const FUTURE_DAYS_SIZE = 90;

const messages = {
    today: '이번 달',
    previous: '이전',
    next: '다음',
};


const renderEvent = (event) => {
    const link = `https://example.com/`;
    return (
      <a href={link} >
        {event.title}
      </a>
    );
}

  
const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = 'unset';
    const fontSize = '14px';
    //const textDecoration = 'underline';
    const textAlign = 'center';
    const color = event.price==0? 'gray' : event.color;
    const textUnderlineOffset = '5px';
    return {
      style: {
        backgroundColor,
        fontSize,
        //textDecoration,
        textAlign,
        color,
        textUnderlineOffset
      },
      children: renderEvent(event),
    };
  };
  


function PriceChart(props) {

    //console.log("PriceChart ----", props);

    //const [price_list, setPriceList] = useState(Array(FUTURE_DAYS_SIZE).fill(0));
    const [events, setEvents] = useState([]);
    const [avg_price, setAvgPrice] = useState(0);

    useEffect(() => {
        //console.log("PriceChart - useEffect");
        LoadPrice();
    }, [props])


    const LoadPrice = () => {

        if(props.h_id == 0) {
            console.log("LoadPrice exit");
            return;
        }

        //console.log("LoadPrice", props.h_id);
        var new_prices = [];

        fetch('/data/price/h_price.csv')
        .then(response => response.text())
        .then(data => {
            // Do something with the parsed CSV data
            const rows = data.trim().split('\n');
            const headers = rows.shift().split(',');
            //console.log(headers);
            //console.log(rows);
            var price_sum = 0
            var price_count = 0;
            for(var i=0;i<rows.length;i++) {
                var cells = rows[i].split(',');
                if(cells[0] == props.h_id) {
                    for(var j=0;j<cells.length;j++) {
                        //console.log(cells[j], headers[j]);
                        var h_split = headers[j].split('-');
                        var yy = parseInt(h_split[0].substring(1,));
                        var mm = parseInt(h_split[1])-1;
                        var dd = parseInt(h_split[2]);
                        var checkin_date = new Date(yy, mm, dd);
                        var checkout_date = new Date();
                        checkout_date.setDate(checkin_date.getDate() + 1);
                                            
                        var ev = {};
                        ev.price = parseFloat(cells[j]);
                        if(ev.price > 0) {
                            ev.title = parseInt(parseInt(cells[j])/10000)+'만원';
                            price_count++;
                            price_sum += ev.price;
                        }
                        else {
                            ev.title = "(남은 객실 없음)";
                        }
                        ev.start = checkin_date;
                        ev.end = checkin_date;
                        ev.link_url = '&currency=KRW&checkin='+PinoUtil.PINO_getDispDateText(checkin_date)+'&checkin='+PinoUtil.PINO_getDispDateText(checkout_date);
                        //console.log(ev);

                        new_prices.push(ev);
                    }
                    break;
                }
            }

            //console.log("price_sum:", price_sum);
            //console.log("price_count:", price_count);
            var avg = (price_sum / price_count).toFixed(0);
            setAvgPrice(avg);

            //console.log(new_prices);
            for(var k=0;k<new_prices.length;k++) {
                if(new_prices[k].price > avg_price * 1.1) {
                    new_prices[k].color = 'red';
                }
                else if(new_prices[k].price < avg_price * 0.9) {
                    new_prices[k].color = 'blue';
                }
                else {
                    new_prices[k].color = 'green';
                }
            }

            setEvents(new_prices);
        })
        .catch(error => {
            // Handle error
            console.error(error);
        });

    }
    

    const onDoubleClickEvent = (ev) => {
        console.log("onDoubleClickEvent");
    }
    
    const handleSelected = (ev) => {
        console.log("handleSelected", ev);
        var priceLink = PinoUtil.AGODA_LINK_URL+props.h_id+ev.link_url;
        console.log(priceLink);
        window.open(priceLink);
    }

    return  (
    <div className="price_chart_div">
        <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            views={['month']}
            selectable={true}
            eventPropGetter={eventStyleGetter}
            style={{ height: '100%' }}
            messages={messages}
            onDoubleClickEvent={onDoubleClickEvent}
            onSelectEvent={handleSelected}
        />
    </div>
    )
}

export default PriceChart;